import revive_payload_client_4sVQNw7RlN from "/opt/build/repo/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/opt/build/repo/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/opt/build/repo/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_jtc2qNDx4l from "/opt/build/repo/node_modules/nuxt-site-config/dist/runtime/app/plugins/0.siteConfig.js";
import payload_client_yVLowv6hDl from "/opt/build/repo/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/opt/build/repo/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/opt/build/repo/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/opt/build/repo/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_QjNYEGgaKG from "/opt/build/repo/node_modules/@vue-final-modal/nuxt/dist/runtime/plugin.mjs";
import plugin_zbacBAXhj0 from "/opt/build/repo/.nuxt/modules/@nuxt-scripts/plugin.mjs";
import components_plugin_KR1HBZs4kY from "/opt/build/repo/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/opt/build/repo/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_WLsn00x1qh from "/opt/build/repo/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import plugin_cmDI8mptrG from "/opt/build/repo/node_modules/nuxt3-lenis/dist/runtime/plugin.mjs";
import gsapPlugin_HlwIIyl85L from "/opt/build/repo/.nuxt/gsapPlugin.mjs";
import plugin_BarBBvfGZB from "/opt/build/repo/node_modules/nuxt-rellax/dist/runtime/plugin.js";
import plugin_client_Fdvg3o8568 from "/opt/build/repo/node_modules/@nuxtjs/web-vitals/dist/runtime/plugin.client.mjs";
import click_outside_D2NiLGp7dJ from "/opt/build/repo/plugins/click-outside.ts";
import vue_awesome_paginate_client_SKBrb04POb from "/opt/build/repo/plugins/vue-awesome-paginate.client.ts";
import vue_collapse_W0BA6V8SjQ from "/opt/build/repo/plugins/vue-collapse.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_jtc2qNDx4l,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_QjNYEGgaKG,
  plugin_zbacBAXhj0,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_WLsn00x1qh,
  plugin_cmDI8mptrG,
  gsapPlugin_HlwIIyl85L,
  plugin_BarBBvfGZB,
  plugin_client_Fdvg3o8568,
  click_outside_D2NiLGp7dJ,
  vue_awesome_paginate_client_SKBrb04POb,
  vue_collapse_W0BA6V8SjQ
]