<script setup lang="ts">
import RichTextRenderer from "contentful-rich-text-vue-renderer";
import { Collapse } from "vue-collapsed";
import type { Document } from "@contentful/rich-text-types";
import nodeRenderers from "~/lib/node-renderers.js";

defineProps<{
  text?: Document;
  extraText?: Document;
}>();

const showExtraText = ref(false);
</script>

<template>
  <div class="rich-text">
    <RichTextRenderer
      v-if="text"
      :document="text"
      :node-renderers="nodeRenderers"
    />
  </div>

  <div v-if="extraText">
    <div
      class="mt-4 cursor-pointer font-bold text-blue"
      @click="() => (showExtraText = !showExtraText)"
    >
      Read
      <span v-if="!showExtraText">more</span>
      <span v-else>less</span>
    </div>
    <Collapse
      :when="showExtraText"
      class="mt-4"
    >
      <div class="rich-text">
        <RichTextRenderer
          :document="extraText"
          :node-renderers="nodeRenderers"
        />
      </div>
    </Collapse>
  </div>
</template>
