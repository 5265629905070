const redirects: { [0]: RegExp; [1]: string }[] = [
  [/(.+)\/+$/, "$1"], // Remove trailing slashes
  [/^\/index.asp/, "/"],
  [/^\/calendar_list.asp/, "/events-and-networking/events-calendar"],
  [/^\/trends/, "/research/trends-reports"],
  [/^\/programs/, "/"],
  [/^\/media/, "/contact-us"],
  [/^\/deleteme/, "/"],
  [/^\/donate/, "/"],
  [/^\/articles/, "/news"],
  [/^\/newsletters/, "/news"],
  [/^\/globalsri/, "/"],
  [/^\/committees/, "/membership/committees"],
  [/^\/environmental/, "/policy"],
  [/^\/social/, "/policy"],
  [/^\/governance/, "/policy"],
  [/^\/financialreform/, "/policy"],
  [/^\/accesstosriinvesting/, "/policy"],
  [/^\/disclosure/, "/policy"],
  [/^\/policyletters/, "/policy/policy-letters-and-statements"],
  [/^\/aum/, "/membership"],
  [/^\/assetowners/, "/membership"],
  [/^\/fp/, "/membership"],
  [/^\/ci/, "/membership"],
  [/^\/nonprofit/, "/membership"],
  [/^\/ci_retail/, "/education"],
  [/^\/ci_accredited/, "/education"],
  [/^\/ci_assetmanagers/, "/education"],
  [/^\/trends/, "/research/trends-reports"],
  [/^\/courses/, "/education"],
  [/^\/sribasics/, "/education"],
  [/^\/racialjusticeresources/, "/education"],
  [/^\/retailinvestors/, "/education"],
  [/^\/communityinvesting/, "/education"],
  [/^\/climatereinvestment/, "/education"],
  [/^\/retirement/, "/education"],
  [/^\/pubs/, "/research/research-and-publications"],
  [/^\/memberresearch/, "/research/member-reports"],
  [/^\/plenaries/, "/events-and-networking/conference"],
  [/^\/weblogo/, "/"],
  [/^\/org_documents/, "/"],
  [/^\/recordings/, "/"],
  [/^\/strandedassets/, "/education"],
  [/^\/currentandpast/, "/research/trends-reports"],
  [/^\/mediacenter/, "/contact-us"],
  [/^\/fastfacts/, "/education"],
  [/^\/esg/, "/education"],
  [/^\/performance/, "/education"],
  [/^\/proxies/, "/education"],
  [/^\/resolutions/, "/education"],
  [/^\/misperceptions/, "/education"],
  [/^\/staff/, "/about/our-team"],
  [/^\/annualreports/, "/about/annual-reports"],
  [/^\/slidesrecordings/, "/"],
  [/^\/forum2024/, "/events-and-networking/conference"],
  [/^\/2023memberhillday/, "/events-and-networking/us-sif-capitol-hill-day"],
  [/^\/courses_individualinvestors/, "/education"],
  [/^\/courses_individualinvestors_nextsteps/, "/education"],
  [/^\/courses_fundamentals/, "/education"],
  [/^\/courses_csric/, "/education"],
  [/^\/2023memberday/, "/events-and-networking"],
  [/^\/2023photos/, "/"],
  [/^\/joanbavaria/, "/joan-bavaria-award"],
  [/^\/2024pdsscholars/, "/news/press-release/us-sif-announces-2024-peter-desimone-student-scholarship-recipients"],
  [/^\/financialadvisorsday/, "/events-and-networking"],
  [/^\/assoc_subscribe.asp/, "/join/contact"],
  [/^\/AF_MemberDirectory.asp/, "/"],
  [/^\/forum_new.asp/, "/events-and-networking/conference"],
  [/^\/article_home.asp/, "/news/insights/happy-1-year-anniversary-congressional-sustainable-investment-caucus"],
  [/^\/jobs_search.asp/, "/"],
  [/^\/search.asp/, "/"],
  [/^\/store_home.asp/, "/"],
  [/^\/calendar_list.asp/, "/events-and-networking"],
  [/^\/calendar_month.asp/, "/events-and-networking"],
  [/^\/calendar_week.asp/, "/events-and-networking"],
  [/^\/eventsearch.asp/, "/events-and-networking"],
  [/^\/blog_home.asp/, "/news"],
  [/^\/article_content.asp/, "/news"],
  [/^\/store_product.asp/, "/research/trends-reports"],
  [/^\/conference/, "/events-and-networking/conference"],
  [/^\/board/, "/about/board-of-directors"],
  [/^\/AF_Membership.asp/, "/"],
  // [/^\/contact/, "/contact-us"],
  // [/^\/member/, "/membership"],
];

const queryRedirects: { [0]: Record<string, string>; [1]: string }[] = [
  [{ contentid: "106" }, "/"],
  [{ contentid: "148" }, "/"],
  [{ contentid: "164" }, "/education"],
  [{ contentid: "167" }, "/"],
  [{ contentid: "222" }, "/events-and-networking/conference"],
];

const tempRedirects: { [0]: RegExp; [1]: string }[] = [
];

export default defineNuxtRouteMiddleware(async (to) => {
  if (import.meta.client) {
    return;
  }

  for (const redirect of tempRedirects) {
    if (to.path.match(redirect[0])) {
      return navigateTo(
        to.path.replace(redirect[0], redirect[1]).replace(/\/+/, "/"),
        {
          redirectCode: 307,
        },
      );
    }
  }

  for (const redirect of redirects) {
    if (to.path.match(redirect[0])) {
      return navigateTo(
        to.path.replace(redirect[0], redirect[1]).replace(/\/+/, "/"),
        {
          redirectCode: 301,
        },
      );
    }
  }

  for (const redirect of queryRedirects) {
    if (Object.keys(redirect[0]).every((key) => to.query[key] === redirect[0][key])) {
      return navigateTo(redirect[1], { redirectCode: 301 });
    }
  }
});
