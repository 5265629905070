<script lang="ts" setup>
import type { Entry } from "contentful";
import { accessLevel } from "~/lib/downloads";
import type { TypeDownloadSkeleton } from "~/types/contentful";

const props = defineProps<{
  item: Entry<TypeDownloadSkeleton, "WITHOUT_UNRESOLVABLE_LINKS", "en-GB">;
  type?: string;
}>();

const user = useUser();

const { production } = useRuntimeConfig().public;

const openModal = ref(false);

const downloadFile = () => {
  const link = document.createElement("a");
  link.href = props.item.fields.file?.fields.file?.url ?? "";
  link.target = "_blank";
  link.click();
};
</script>

<template>
  <template v-if="type === 'icon'">
    <NuxtLink
      v-if="accessLevel(user, item) === 'full'"
      :to="item.fields.file?.fields.file?.url"
      target="_blank"
      class="inline-flex size-10 items-center justify-center rounded-md bg-blue"
      @click="
        (event) => {
          if (!user && item.fields.downloadType?.fields.title != 'Annual Report') {
            event.preventDefault();
            openModal = true;
          }
        }
      "
    >
      <NuxtIcon
        class="text-white"
        name="ussif:download"
      />
    </NuxtLink>

    <div
      v-else-if="accessLevel(user, item) === 'locked' || accessLevel(user, item) === 'pay'"
      class="inline-flex size-10 items-center justify-center rounded-md bg-blue"
    >
      <NuxtLink to="/login">
        <NuxtIcon
          class="text-white"
          name="ussif:lock"
        />
      </NuxtLink>
    </div>
  </template>

  <template v-if="type === 'secondary'">
    <UiButton
      v-if="accessLevel(user, item) === 'full'"
      button-type="secondary"
      button-icon="download"
    >
      <NuxtLink
        :to="item.fields.file?.fields.file?.url"
        target="_blank"
        @click="
          (event) => {
            if (!user && item.fields.downloadType?.fields.title != 'Annual Report') {
              event.preventDefault();
              openModal = true;
            }
          }
        "
      >
        Download
      </NuxtLink>
    </UiButton>
    <UiButton
      v-if="accessLevel(user, item) === 'pay'"
      button-type="secondary"
      button-icon="lock"
    >
      <NuxtLink
        :to="
          production
            ? 'https://buy.stripe.com/8wM9Db4vM1lR58I9AA'
            : 'https://buy.stripe.com/test_4gw02beJJ8tQd1e288'
        "
        target="_blank"
      >
        Purchase Report
      </NuxtLink>
    </UiButton>
  </template>

  <template v-if="type === 'text'">
    <NuxtLink
      v-if="accessLevel(user, item) === 'full'"
      :to="item.fields.file?.fields.file?.url"
      target="_blank"
      @click="
        (event) => {
          if (!user && item.fields.downloadType?.fields.title != 'Annual Report') {
            event.preventDefault();
            openModal = true;
          }
        }
      "
    >
      {{ item.fields.title }}
    </NuxtLink>
  </template>

  <LazyOtherModal
    v-model="openModal"
    title="To download please fill in your details"
    size="small"
    class="z-50 flex items-center justify-center"
  >
    <FormUserDataCapture
      :download-file-name="item.fields.title"
      :download-file-url="item.fields.file?.fields.file?.url"
      @success="
        () => {
          downloadFile();
          openModal = false;
        }
      "
    />
  </LazyOtherModal>
</template>
