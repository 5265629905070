<script lang="ts" setup>
import type { EntryCollection } from "contentful";
import type {
  TypeArticleSkeleton,
  TypeBlockNewsGrid,
} from "~/types/contentful";

defineProps<{
  fields: TypeBlockNewsGrid<"WITHOUT_UNRESOLVABLE_LINKS", "en-GB">["fields"];
}>();

const { data: articles }
  = await useLazyFetch<
    EntryCollection<TypeArticleSkeleton, "WITHOUT_UNRESOLVABLE_LINKS", "en-GB">
  >("/api/articles");
</script>

<template>
  <UiContainer class="bg-navy text-white">
    <section class="relative grid-cols-12 gap-5 pb-10 lg:grid">
      <div class="col-span-12">
        <h2>
          {{ fields.title }}
        </h2>
      </div>

      <div class="col-span-12">
        <div class="w-full justify-between lg:flex">
          <div class="no-scrollbar -mx-4 flex overflow-auto px-4">
            <div class="btn mr-2">
              News
            </div>
            <div class="btn mr-2">
              Reports
            </div>
            <div class="btn">
              Statements
            </div>
          </div>

          <div
            class="btn--secondary clear-both inline-block text-white max-sm:float-right"
          >
            View all news
          </div>
        </div>
      </div>
    </section>

    <section
      v-if="articles"
      class="relative mt-12 grid grid-cols-12 gap-4 pb-6"
    >
      <div class="col-span-12 hidden lg:col-span-3 lg:block">
        <BlockNewsGridItem
          v-if="articles.items[1]"
          :article="articles.items[1]"
          :featured-left="true"
          class="mb-7"
        />
      </div>
      <div class="col-span-12 lg:col-span-6">
        <BlockNewsGridItem
          v-if="articles.items[0]"
          :article="articles.items[0]"
          :featured-main="true"
          class="mb-7"
        />
      </div>
      <div class="hidden lg:col-span-3 lg:block">
        <BlockNewsGridItem
          v-if="articles.items[2]"
          :article="articles.items[2]"
          class="mb-7"
        />
        <BlockNewsGridItem
          v-if="articles.items[3]"
          :article="articles.items[3]"
          class="mb-7"
        />
      </div>

      <div class="col-span-6 lg:hidden">
        <BlockNewsGridItem
          v-if="articles.items[1]"
          :article="articles.items[1]"
        />
      </div>

      <div class="col-span-6 lg:hidden">
        <BlockNewsGridItem
          v-if="articles.items[2]"
          :article="articles.items[2]"
        />
      </div>
    </section>
  </UiContainer>
</template>
