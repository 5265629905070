<script lang="ts" setup>
import RichTextRenderer from "contentful-rich-text-vue-renderer";
import type { TypeBlockProfileCards } from "~/types/contentful";
import nodeRenderers from "~/lib/node-renderers.js";

defineProps<{
  fields: TypeBlockProfileCards<"WITHOUT_UNRESOLVABLE_LINKS", "en-GB">["fields"];
}>();

const open = ref(false);
const staffSlugPopup = ref("");
</script>

<template>
  <UiContainer class="relative bg-white">
    <UiSectionTitle
      v-if="fields.displayTitle"
      :title="fields.title"
      :subtitle="fields.subtitle"
      :title-alignment="fields.titleAlignment"
    />

    <section class="relative border-blue md:border-b-[6px] md:pb-10">
      <UiButton
        v-if="fields.link && fields.link.content"
        button-type="secondary"
        button-icon="slash"
        class="md:absolute md:-top-20 md:right-0"
      >
        <RichTextRenderer
          :document="fields.link"
          :node-renderers="nodeRenderers"
        />
      </UiButton>

      <div
        v-if="fields.titleAlignment !== 'Center'"
        class="grid grid-cols-12 gap-y-4 pb-12 pt-4 md:gap-x-16"
      >
        <div
          v-if="fields.subtitle"
          class="col-span-12 pt-1 lg:col-span-6 lg:mt-[-3px] lg:text-desktop-xl lg:leading-10"
        >
          {{ fields.subtitle }}
        </div>

        <div class="col-span-12 pt-1 lg:col-span-6">
          <RichTextRenderer
            v-if="fields.text"
            :document="fields.text"
            :node-renderers="nodeRenderers"
          />
        </div>
      </div>

      <div class="grid gap-6 sm:grid-cols-2 lg:grid-cols-4 lg:gap-10">
        <template v-for="card in fields.items">
          <CardStaff
            v-if="card"
            :key="card?.sys.id"
            :fields="card.fields"
            :popup="card.fields.shortBio || card.fields.description ? true : false"
            @popup="
              ($event: string) => {
                open = true;
                staffSlugPopup = $event;
              }
            "
          />
        </template>
      </div>
    </section>

    <LazyOtherModal
      v-model="open"
      size="large"
      class="flex items-center justify-center"
    >
      <template v-for="staff in fields.items">
        <div
          v-if="staff?.fields.slug == staffSlugPopup"
          :key="staff.sys.id"
          class="grid grid-cols-12 lg:gap-8 lg:p-10"
        >
          <CardStaff
            class="col-span-12 lg:col-span-4"
            :fields="staff.fields"
          />
          <div class="col-span-12 lg:col-span-8 lg:pr-6">
            <p class="-mt-1 mb-3 text-3xl lg:text-desktop-3xl">
              {{ fields.popupHeading }}
            </p>

            <RichTextRenderer
              v-if="staff.fields.shortBio"
              :document="staff.fields.shortBio"
              :node-renderers="nodeRenderers"
            />

            <p class="mb-3 mt-6 text-3xl lg:text-desktop-3xl">
              {{ fields.secondPopupHeading }}
            </p>

            <div v-if="staff.fields.description">
              <RichTextRenderer
                :document="staff.fields.description"
                :node-renderers="nodeRenderers"
              />
            </div>
          </div>
        </div>
      </template>
    </LazyOtherModal>
  </UiContainer>
</template>
