<script lang="ts" setup>
import RichTextRenderer from "contentful-rich-text-vue-renderer";
import type { TypeBlockBannerElement } from "~/types/contentful";
import nodeRenderers from "~/lib/node-renderers.js";
import { isTypeDownload } from "~/types/contentful";

const props = defineProps<{
  fields: TypeBlockBannerElement<"WITHOUT_UNRESOLVABLE_LINKS", "en-GB">["fields"];
}>();

const sectionClass = computed(() => ({
  "md:-mt-24 lg:-mt-48": props.fields.overlayPreviousModule,
}));
</script>

<template>
  <UiContainer
    :card-container="true"
    :class="sectionClass"
  >
    <section class="relative overflow-hidden bg-cream px-6 py-10 md:rounded-lg md:py-14">
      <div class="relative grid grid-cols-12 items-center gap-x-4">
        <div
          class="col-span-12 flex flex-col items-center text-center md:col-span-6 md:col-start-2 md:items-start md:text-left lg:col-span-4 lg:col-start-3"
        >
          <h3>
            {{ fields.title }}
          </h3>

          <div class="rich-text">
            <RichTextRenderer
              :document="fields.content"
              :node-renderers="nodeRenderers"
            />
          </div>

          <div class="mt-10 flex flex-col gap-4">
            <UiButton
              v-if="fields.link"
              button-type="primary"
              button-theme="dark"
            >
              <RichTextRenderer
                :document="fields.link"
                :node-renderers="nodeRenderers"
              />
            </UiButton>

            <UiButton
              v-if="fields.link_extra"
              button-type="secondary"
            >
              <RichTextRenderer
                :document="fields.link_extra"
                :node-renderers="nodeRenderers"
              />
            </UiButton>

            <UiButton
              v-if="fields.link_extra"
              button-type="secondary"
            >
              <RichTextRenderer
                :document="fields.link_super_extra"
                :node-renderers="nodeRenderers"
              />
            </UiButton>

            <UiDownloadLink
              v-if="fields.downloadFile && isTypeDownload(fields.downloadFile)"
              :item="fields.downloadFile"
              type="secondary"
            />
          </div>
        </div>

        <div
          class="col-span-8 col-start-3 mt-10 md:col-span-4 md:col-start-8 md:mt-0 lg:col-span-3 lg:col-start-8"
        >
          <NuxtImg
            v-if="fields.image"
            v-rellax
            data-rellax-xs-speed="0"
            data-rellax-speed="1"
            data-rellax-percentage="0.5"
            class="w-full"
            :src="fields.image?.fields.file?.url"
            :alt="fields.image?.fields.title"
            :width="300"
            loading="lazy"
            quality="80"
            format="webp"
          />
        </div>
      </div>
    </section>
  </UiContainer>
</template>

<style lang="scss" scoped>
:deep(section) {
  &::before {
    @apply content-[""] absolute bottom-0 left-[65%] md:left-[75%] h-[600px] w-[600px] bg-green [clip-path:polygon(38%_0%,100%_0,100%_100%,0%_100%)];
  }

  img {
    box-shadow: 15px 15px 75px #00000040;
  }
}
</style>
