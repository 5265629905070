<script lang="ts" setup>
import type { Entry } from "contentful";
import {
  isTypeArticle,
  isTypeDownload,
  type TypeArticleSkeleton,
  type TypeDownloadSkeleton,
} from "~/types/contentful";
import { hasImage } from "~/lib/article/type-guards.js";
import { formatDate } from "~/lib/date-time";
import { accessLevel } from "~/lib/downloads";

const props = defineProps<{
  item: Entry<TypeArticleSkeleton | TypeDownloadSkeleton, "WITHOUT_UNRESOLVABLE_LINKS", "en-GB">;
  header?: boolean;
}>();

const user = useUser();

const type = computed(() => {
  if (isTypeArticle(props.item)) {
    return props.item.fields.articleType?.fields.title;
  }

  if (isTypeDownload(props.item)) {
    return props.item.fields.downloadType?.fields.title;
  }

  return undefined;
});

const date = computed(() => {
  if (isTypeArticle(props.item)) {
    return formatDate(new Date(props.item.fields.publishDate).toISOString());
  } else if (isTypeDownload(props.item)) {
    return formatDate(new Date(props.item.fields.date).toISOString());
  } else {
    return undefined;
  }
});

const itemImage = computed(() =>
  hasImage(props.item)
    ? props.item.fields.image
    : isTypeDownload(props.item) && props.item.fields.downloadType?.fields.image
      ? props.item.fields.downloadType.fields.image
      : "",
);
</script>

<template>
  <div class="hub-item relative w-full">
    <div class="absolute z-0 size-full shadow-[0_0_20px_0] shadow-light-grey" />

    <section class="relative z-10 grid grid-cols-12 gap-4 rounded-xl p-6 lg:p-8">
      <div
        v-if="itemImage"
        :class="{
          'col-span-6 col-start-4': !header,
          'col-span-6 col-start-4 lg:col-span-10 lg:col-start-2': header,
        }"
      >
        <NuxtLink
          :to="isTypeArticle(item) ? getUrl(item) : ''"
          class="!flex justify-center"
        >
          <NuxtImg
            v-if="itemImage && itemImage.fields.file"
            class="mb-4 !object-contain drop-shadow-[0_0_14px_#e0e0e0]"
            :class="{
              'max-h-40': !header,
            }"
            :src="itemImage.fields.file.url"
            :alt="itemImage.fields.title"
            :width="220"
            :height="300"
            fit="contain"
            loading="lazy"
          />
        </NuxtLink>
      </div>

      <div
        v-if="!header"
        class="col-span-3 flex justify-end"
      >
        <UiDownloadLink
          v-if="isTypeDownload(item)"
          :item="item"
          type="icon"
        />
      </div>

      <div
        v-if="header"
        class="col-span-12 flex flex-col items-center justify-center"
      >
        <UiDownloadLink
          v-if="isTypeDownload(item)"
          :item="item"
          type="secondary"
        />

        <NuxtLink
          v-if="
            isTypeDownload(item)
              && (accessLevel(user, item) === 'locked' || accessLevel(user, item) === 'pay')
          "
          to="/login"
          class="btn--link btn--link--blue mt-4 font-bold"
        >
          or Login
        </NuxtLink>
      </div>

      <div
        v-if="!header"
        class="col-span-12 flex flex-col"
      >
        <h4 class="mb-5">
          <!-- <template v-if="isTypeDownload(item) && accessLevel(user, item) === 'full'">
            <NuxtLink :to="item.fields.file?.fields.file?.url">
              {{ item.fields.title }}
            </NuxtLink>
          </template> -->
          <UiDownloadLink
            v-if="item && isTypeDownload(item)"
            :item="item"
            type="text"
          />
          <template v-else>
            <NuxtLink to="/login">
              {{ item.fields.title }}
            </NuxtLink>
          </template>
        </h4>

        <div class="mb-4 flex items-center">
          <UiBadge
            v-if="type"
            bg-colour="#e8e8e8"
          >
            {{ type }}
          </UiBadge>
        </div>
      </div>

      <div
        v-if="!header"
        class="col-span-12"
      >
        <UiDate
          :date="date"
          hide-slash
        />

        <div
          v-if="isTypeDownload(item) && item.fields.report"
          class="absolute bottom-6 right-6 md:right-8"
        >
          <UiButton
            button-type="primary"
            button-style="outline"
            button-theme="light"
            button-icon="slash"
            class="mt-10"
          >
            <NuxtLink :to="getUrl(item.fields.report)">
              Read <span class="ms-1 hidden md:inline">Report</span>
            </NuxtLink>
          </UiButton>
        </div>
      </div>
    </section>
  </div>
</template>

<style lang="scss" scoped>
.hub-item {
  &__image:deep(.header-info__image) {
    img {
      @apply m-auto max-h-[230px];
    }
  }
}
</style>
