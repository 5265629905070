<script lang="ts" setup>
import type { EntrySys } from "contentful";
import RichTextRenderer from "contentful-rich-text-vue-renderer";
import { isActiveNav } from "~/lib/routing.js";
import type { TypeNavigationMain } from "~/types/contentful";
import nodeRenderers from "~/lib/node-renderers.js";

const user = useUser();

const { isLargeDesktop } = useResponsive();

const overlay = useOverlay();

const props = defineProps<{
  fields: TypeNavigationMain<"WITHOUT_UNRESOLVABLE_LINKS", "en-GB">["fields"];
  sys: EntrySys;
}>();

// We source nav items from here, instead of through contentful's resolver,
// so we have smaller payloads
const { data: navItems } = await useFetch("/api/nav", {
  query: { id: props.sys.id },
});

const logout = () => {
  if (user.value) {
    $fetch("/api/auth/logout", { method: "POST" }).then(() => (user.value = null));
  }
};

const visibleDropdown = ref<number | null>(null);
const dropdown = ref<HTMLElement | null>(null);

const route = useRoute();

const isSidebarOpen = ref(false);

const toggleSidebar = () => {
  isSidebarOpen.value = !isSidebarOpen.value;
  overlay.value = isSidebarOpen.value;
};

watch(route, () => (visibleDropdown.value = null));

const onMouseClick = (index: number) => {
  if (visibleDropdown.value === index) {
    displayDropdown(false);
  } else {
    displayDropdown(true, index);
  }
  window.addEventListener("scroll", handleScroll);
};

const onNavigate = () => {
  displayDropdown(false);
  isSidebarOpen.value = false;
  if (!isLargeDesktop.value) {
    overlay.value = isSidebarOpen.value;
  }
};

const onDropdownClickOutside = (event: Event) => {
  if (isLargeDesktop.value) {
    if ((event.target as HTMLElement).id !== "dropdown") {
      displayDropdown(false);
    }
  }
};

const handleScroll = () => {
  if (isLargeDesktop.value) {
    if (window.scrollY > 200) {
      displayDropdown(false);
      window.removeEventListener("scroll", handleScroll);
    }
  }
};

const displayDropdown = (displayState: boolean, index?: number) => {
  visibleDropdown.value = displayState && index !== undefined ? index : null;
  if (isLargeDesktop.value) {
    overlay.value = displayState;
  }
};
</script>

<!-- eslint-disable-next-line vue/max-lines-per-block -->
<template>
  <header class="flex h-[68px] items-center lg:h-[94px]">
    <div class="container relative mx-auto flex items-center justify-between">
      <div class="flex w-full items-center justify-between">
        <div class="flex items-center justify-between max-xl:w-full">
          <!-- Logo -->
          <div class="mr-10 flex h-10 max-xl:order-2">
            <NuxtLink
              to="/"
              class="w-[125px]"
            >
              <NuxtIcon
                name="ussif:logo"
                class="size-full text-navy xl:text-white"
              />
            </NuxtLink>
          </div>
          <!-- Sidebar toggle -->
          <button
            class="flex size-10 items-center justify-center max-xl:order-3 xl:hidden"
            @click="toggleSidebar"
          >
            <NuxtIcon
              name="ussif:hamburger"
              size="24"
            />
          </button>
          <!-- Desktop Menu -->
          <!-- Navigation Links -->
          <nav class="flex bg-navy pr-4 max-xl:hidden lg:space-x-6 xl:space-x-8">
            <div
              v-for="(item, index) in navItems"
              :key="index"
              class=""
              :class="{
                active: isActiveNav(item, route),
              }"
            >
              <!-- top level nav items -->
              <NuxtLink
                v-if="'link' in item && 'label' in item"
                :to="item.link"
                class="btn--link text-white"
              >
                {{ item.label }}
              </NuxtLink>

              <!-- dropdown nav item -->
              <div
                v-else-if="'primaryLinks' in item"
                class=""
              >
                <div
                  class="flex items-center"
                  @click="onMouseClick(index)"
                >
                  <span class="btn--link text-white">
                    {{ item.name }}
                  </span>
                  <NuxtIcon
                    name="ussif:arrow"
                    class="ms-2 text-sm text-white transition"
                    :class="{
                      'rotate-180': visibleDropdown === index,
                    }"
                  />
                </div>

                <!-- dropdown -->
                <Transition name="fade">
                  <template v-if="'primaryLinks' in item">
                    <div
                      v-if="visibleDropdown === index"
                      ref="dropdown"
                      v-click-outside="onDropdownClickOutside"
                      class="absolute left-0 top-16 z-40 w-full overflow-hidden rounded bg-white shadow-md"
                    >
                      <div class="relative grid grid-cols-12 gap-4 bg-white">
                        <!-- dropdown promo -->
                        <div
                          v-if="item.promo.link"
                          class="relative col-span-3"
                        >
                          <NuxtLink
                            :to="item.promo.link"
                            class="block h-full"
                            @click="onNavigate"
                          >
                            <NuxtImg
                              v-if="item.promo.image"
                              :src="item.promo.image"
                              :alt="item.promo.name"
                              :width="314"
                              :height="419"
                              fit="cover"
                              class="size-full object-cover"
                            />
                            <div class="absolute bottom-0 bg-black/80 p-6 text-center text-white">
                              <h4>
                                {{ item.promo.name }}
                              </h4>
                              <p class="mt-2">
                                {{ item.promo.copy }}
                              </p>
                            </div>
                          </NuxtLink>
                        </div>

                        <!-- dropdown links -->
                        <div
                          class="p-20"
                          :class="{
                            'col-span-9': item.promo.link,
                            'col-span-12': !item.promo.link,
                          }"
                        >
                          <div
                            v-if="item.primaryLinks.length"
                            class="grid grid-cols-12 gap-12"
                          >
                            <NuxtLink
                              v-for="(primaryLink, primaryLinkIndex) in item.primaryLinks"
                              :key="primaryLinkIndex"
                              :to="primaryLink.link"
                              class="col-span-12"
                              @click="onNavigate"
                            >
                              <div class="flex w-fit items-center border-b-2 border-blue pb-2">
                                <span class="me-4 font-bold text-blue">
                                  {{ primaryLink.label }}
                                </span>
                                <NuxtIcon
                                  name="ussif:arrow-right"
                                  class="size-4 text-blue"
                                />
                              </div>
                            </NuxtLink>

                            <div
                              v-for="(link, linkIndex) in item.items"
                              :key="linkIndex"
                              class="col-span-6"
                            >
                              <NuxtLink
                                :key="linkIndex"
                                :to="link.link"
                                @click="onNavigate"
                              >
                                <span class="btn--link bg-[linear-gradient(#000,#000)] font-bold">
                                  {{ link.label }}
                                </span>
                                <p class="pt-2">
                                  {{ link.copy }}
                                </p>
                              </NuxtLink>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </template>
                </Transition>
              </div>
            </div>
          </nav>
        </div>
        <!-- Right Side Buttons -->
        <div class="hidden items-center justify-end space-x-4 xl:flex">
          <!-- Search -->
          <NuxtLink
            to="/search?q=&p=1"
            class="flex size-[48px] items-center justify-center transition-opacity hover:opacity-70"
          >
            <NuxtIcon
              class="text-white"
              name="ussif:search"
              size="20"
            />
          </NuxtLink>
          <template v-if="!user">
            <UiButton
              button-type="primary"
              button-theme="dark"
            >
              <NuxtLink to="/login"> Login </NuxtLink>
            </UiButton>
            <UiButton
              button-type="primary"
              button-theme="dark"
            >
              <NuxtLink to="/join"> Join Us </NuxtLink>
            </UiButton>
          </template>
          <template v-else>
            <UiButton
              button-type="primary"
              button-theme="dark"
            >
              <button @click.prevent="logout">
                Logout
              </button>
            </UiButton>
          </template>
        </div>
      </div>
    </div>
    <!-- Mobile Menu -->
    <div
      class="fixed inset-y-0 z-20 w-[340px] bg-white shadow-lg transition-all duration-300 xl:hidden"
      :class="isSidebarOpen ? 'right-0' : '-right-[340px]'"
    >
      <div class="relative h-[68px] px-6">
        <NuxtLink
          to="/search?q=&p=1"
          class="absolute left-10 top-1/2 flex size-[28px] -translate-y-1/2 items-center justify-center"
          @click="toggleSidebar"
        >
          <NuxtIcon
            class="text-black"
            name="ussif:search"
            size="20"
          />
        </NuxtLink>
        <button
          class="absolute right-6 top-1/2 -translate-y-1/2 focus:outline-none"
          @click="toggleSidebar"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="size-7"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
      </div>
      <div class="h-full overflow-scroll">
        <nav class="flex flex-col px-10 py-2">
          <div
            v-for="(item, index) in navItems"
            :key="index"
            class=""
          >
            <NuxtLink
              v-if="'link' in item"
              :to="item.link"
              class="block py-2 text-xl font-bold"
              @click="onNavigate"
            >
              {{ "label" in item ? item.label : item.name }}
            </NuxtLink>

            <div
              v-else-if="'primaryLinks' in item"
              class=""
            >
              <div
                class="flex cursor-pointer items-center justify-between py-3 text-xl font-bold"
                @click="onMouseClick(index)"
              >
                <span class="">
                  {{ item.name }}
                </span>
                <NuxtIcon
                  name="ussif:arrow-right"
                  class="text-sm"
                />
              </div>
            </div>

            <!-- dropdown -->
            <Transition name="fade">
              <template v-if="'primaryLinks' in item">
                <div
                  v-if="visibleDropdown === index"
                  ref="dropdown"
                  v-click-outside="onDropdownClickOutside"
                  class="absolute left-0 top-[68px] z-40 size-full overflow-hidden bg-white"
                >
                  <div class="relative h-full overflow-y-scroll bg-white px-10 py-6">
                    <!-- dropdown links -->
                    <div
                      v-if="item.primaryLinks.length"
                      class="grid grid-cols-1 gap-y-8 pb-[200px]"
                    >
                      <div
                        class="flex items-center"
                        @click="onMouseClick(index)"
                      >
                        <div class="me-4 rotate-180">
                          <NuxtIcon
                            name="ussif:arrow-right"
                            class="text-sm"
                          />
                        </div>
                        <span class="font-bold">
                          {{ item.name }}
                        </span>
                      </div>
                      <NuxtLink
                        v-for="(primaryLink, primaryLinkIndex) in item.primaryLinks"
                        :key="primaryLinkIndex"
                        :to="primaryLink.link"
                        @click="onNavigate"
                      >
                        <div class="flex w-fit items-center border-b-2 border-blue pb-2">
                          <span class="me-4 font-bold text-blue">
                            {{ primaryLink.label }}
                          </span>
                          <NuxtIcon
                            name="ussif:arrow-right"
                            class="size-4 text-blue"
                          />
                        </div>
                      </NuxtLink>

                      <div
                        v-for="(link, linkIndex) in item.items"
                        :key="linkIndex"
                      >
                        <NuxtLink
                          :key="linkIndex"
                          :to="link.link"
                          @click="onNavigate"
                        >
                          <span class="font-bold">
                            {{ link.label }}
                          </span>
                          <p class="pt-2">
                            {{ link.copy }}
                          </p>
                        </NuxtLink>
                      </div>
                    </div>
                  </div>
                  <!-- dropdown promo -->
                  <div class="absolute bottom-[68px]">
                    <NuxtLink
                      :to="item.promo.link"
                      class="relative block"
                      @click="onNavigate"
                    >
                      <NuxtImg
                        v-if="item.promo.image"
                        :src="item.promo.image"
                        :width="288"
                        :height="111"
                        fit="cover"
                        loading="lazy"
                        :alt="item.promo.name"
                        class="absolute size-full object-cover"
                      />
                      <div class="relative bg-black/40 px-10 py-6 text-white">
                        <h4 class="text-xl font-bold">
                          {{ item.promo.name }}
                        </h4>
                        <p class="mt-1">
                          {{ item.promo.copy }}
                        </p>
                      </div>
                    </NuxtLink>
                  </div>
                </div>
              </template>
            </Transition>
          </div>
        </nav>
        <div class="mb-[100px]">
          <div class="copy px-10 py-4">
            <RichTextRenderer
              :document="props.fields.copy"
              :node-renderers="nodeRenderers"
            />
          </div>
          <!-- Right Side Buttons -->
          <div class="flex flex-col items-center space-y-4 px-10 pt-4 xl:hidden">
            <template v-if="!user">
              <UiButton
                button-type="primary"
                button-theme="dark"
                button-size="full"
              >
                <NuxtLink to="/login"> Login </NuxtLink>
              </UiButton>
              <UiButton
                button-type="primary"
                button-theme="dark"
                button-size="full"
              >
                <NuxtLink to="/join"> Join Us </NuxtLink>
              </UiButton>
            </template>
            <template v-else>
              <UiButton
                button-type="primary"
                button-theme="dark"
                button-size="full"
              >
                <button @click.prevent="logout">
                  Logout
                </button>
              </UiButton>
            </template>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<style lang="scss" scoped>
:deep(.copy) {
  a {
    @apply text-blue font-bold;
  }
}
</style>
