<script lang="ts" setup>
import RichTextRenderer from "contentful-rich-text-vue-renderer";
import type { TypeBlockTabbedList, TypeBlockTabbedListFields } from "~/types/contentful";
import nodeRenderers from "~/lib/node-renderers.js";

type KeyValue = { key: string; value: string };

defineProps<{
  fields: TypeBlockTabbedList<"WITHOUT_UNRESOLVABLE_LINKS", "en-GB">["fields"] & {
    tab1Items: KeyValue[];
    tab2Items: KeyValue[];
    tab3Items: KeyValue[];
    tab4Items: KeyValue[];
    tab5Items: KeyValue[];
    tab6Items: KeyValue[];
  };
}>();

const container = ref<ComponentPublicInstance>();
const selectedTabNumber = ref(1);
const showMobileTabs = ref(false);

watch(selectedTabNumber, () => {
  container.value?.$el.scrollIntoView({
    behavior: "smooth",
    block: "start",
  });
});
</script>

<template>
  <UiContainer ref="container">
    <UiSectionTitle
      v-if="fields.title"
      :title="fields.title"
    />

    <div class="mb-6 mt-8 grid grid-cols-12 gap-y-4 lg:mt-12 lg:gap-x-16">
      <div
        class="col-span-12 max-lg:-mx-6 max-lg:hidden max-lg:w-[calc(100%+48px)] max-lg:overflow-auto max-lg:pl-6 lg:col-span-4"
      >
        <div class="sticky top-10 bg-light-grey max-lg:flex">
          <template
            v-for="(tabNumber, index) in 6"
            :key="index"
          >
            <div
              v-if="fields[`tab${tabNumber}` as keyof TypeBlockTabbedListFields]"
              class="cursor-pointer whitespace-normal border-[#e9e9eb] px-6 py-4 transition-colors max-lg:border-b-4 lg:border-l-4"
              :class="{
                '!border-blue bg-[#f9f9f9] font-bold text-blue': selectedTabNumber === tabNumber,
                'hover:text-blue': selectedTabNumber !== tabNumber,
              }"
              @click="selectedTabNumber = tabNumber"
            >
              {{ fields[`tab${tabNumber}` as keyof TypeBlockTabbedListFields] }}
            </div>
          </template>
        </div>
      </div>

      <!-- mobile -->
      <div class="col-span-12 lg:hidden">
        <div
          class="cursor-pointer font-bold text-blue"
          @click="() => (showMobileTabs = !showMobileTabs)"
        >
          {{ fields[`tab${selectedTabNumber}` as keyof TypeBlockTabbedListFields] }}
          <span>
            <NuxtIcon
              class="ml-2 inline-block"
              :class="{
                'rotate-180': showMobileTabs,
              }"
              name="ussif:arrow"
            />
          </span>
        </div>
        <Collapse
          :when="showMobileTabs"
          class="mt-4"
        >
          <template
            v-for="(tabNumber, index) in 6"
            :key="index"
          >
            <div
              v-if="fields[`tab${tabNumber}` as keyof TypeBlockTabbedListFields]"
              class="cursor-pointer py-1"
              :class="{
                underline: selectedTabNumber === tabNumber,
              }"
              @click="selectedTabNumber = tabNumber"
            >
              {{ fields[`tab${tabNumber}` as keyof TypeBlockTabbedListFields] }}
            </div>
          </template>
        </Collapse>
      </div>

      <div
        v-if="fields[`tab${selectedTabNumber}Items` as keyof TypeBlockTabbedListFields]"
        class="col-span-12 max-lg:mt-6 lg:col-span-8"
      >
        <div class="rich-text">
          <RichTextRenderer
            :document="fields[`tab${selectedTabNumber}Content` as keyof TypeBlockTabbedListFields]"
            :node-renderers="nodeRenderers"
          />
        </div>
        <div
          v-if="!fields[`tab${selectedTabNumber}Content` as keyof TypeBlockTabbedListFields]"
          class="grid gap-12 md:grid-cols-2"
        >
          <template
            v-for="(item, index) in fields[
              `tab${selectedTabNumber}Items` as keyof TypeBlockTabbedListFields
            ]"
            :key="index"
          >
            <div v-if="item">
              <div class="mb-3 text-xl font-bold">
                {{ (item as KeyValue).key }}
              </div>
              <div>
                {{ (item as KeyValue).value }}
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
  </UiContainer>
</template>
