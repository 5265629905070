<script lang="ts" setup>
import RichTextRenderer from "contentful-rich-text-vue-renderer";
import {
  isTypeBlockMediaText,
  isTypeCardTestimonial,
  type TypeCarouselMediaText,
} from "~/types/contentful";
import nodeRenderers from "~/lib/node-renderers.js";

const props = defineProps<{
  fields: TypeCarouselMediaText<"WITHOUT_UNRESOLVABLE_LINKS", "en-GB">["fields"];
}>();

const carouselSettings = {
  itemsToShow: 1,
  itemsToScroll: 1,
  snapAlign: "start",
};

const isTestimonialCarousel = computed(
  () => props.fields.items[0] && isTypeCardTestimonial(props.fields.items[0]),
);
</script>

<template>
  <UiContainer
    class="relative overflow-hidden"
    :class="{
      'bg-navy text-white': !fields.whiteBackground,
    }"
  >
    <section
      class="relative pb-10 before:absolute before:-bottom-36 before:hidden before:size-[600px] before:bg-blue before:content-[''] before:[clip-path:polygon(38%_0%,100%_0,100%_100%,0%_100%)] before:md:left-[99%] before:md:block"
      :class="{ 'before:md:hidden': isTestimonialCarousel }"
    >
      <div class="col-span-12">
        <div class="w-full items-center justify-between lg:flex">
          <h2 v-if="fields.displaySectionTitle !== false">
            {{ fields.title }}
          </h2>

          <UiButton
            v-if="fields.link && fields.link.content"
            button-type="secondary"
            button-icon="slash"
          >
            <RichTextRenderer
              :document="fields.link"
              :node-renderers="nodeRenderers"
            />
          </UiButton>
        </div>
      </div>

      <div class="-mx-6 mt-10">
        <carousel v-bind="carouselSettings">
          <slide
            v-for="item in fields.items"
            :key="item?.sys.id"
            class="!items-start pr-6"
          >
            <BlockMediaText
              v-if="item && isTypeBlockMediaText(item)"
              no-padding
              :fields="{ ...item.fields, mediaPosition: true }"
            />
            <CardTestimonial
              v-else-if="item && isTypeCardTestimonial(item)"
              :fields="item.fields"
            />
          </slide>

          <template #addons>
            <div class="carousel__controls mt-10">
              <pagination />
              <div class="carousel__nav mr-10">
                <navigation />
              </div>
            </div>
          </template>
        </carousel>

        <NuxtIcon
          v-if="!isTestimonialCarousel"
          name="ussif:slash-wider"
          class="absolute -bottom-8 left-[21%] h-auto w-1/6 text-blue max-lg:hidden"
        />
      </div>
    </section>
  </UiContainer>
</template>

<style scoped lang="scss">
:deep(.section-title) {
  @apply hidden;
}

:deep(.mt__text) {
  @apply text-left;
}
</style>
