<script lang="ts" setup>
defineProps<{
  downloadFileName?: string;
  downloadFileUrl?: string;
}>();

const emit = defineEmits(["success"]);
const pending = ref(false);

const onFormSubmit = (event: Event) => {
  pending.value = true;

  const formData = new FormData(event.target as HTMLFormElement);
  const action = (event.target as HTMLFormElement).action;

  fetch(action, {
    method: "POST",
    body: formData,
  }).then(() => {
    emit("success");
  });
};
</script>

<template>
  <!-- @submit="onFormSubmit" -->
  <form
    method="POST"
    action="https://script.google.com/macros/s/AKfycbzdKZ3ybEfvM9v4dQ-LTUexGxGeqWWHwACu_HKikQV71EQAhf2BIc10uvMibkgH-eXoHg/exec"
    @submit.prevent="onFormSubmit"
  >
    <div class="flex w-full gap-3">
      <div class="form-floating mb-3 w-1/2">
        <input
          id="first-name-input"
          type="text"
          name="First Name"
          placeholder="First Name*"
          aria-label="First Name*"
          required
        />
        <label for="first-name-input">First Name</label>
      </div>

      <div class="form-floating mb-3 w-1/2">
        <input
          id="last-name-input"
          type="text"
          name="Last Name"
          placeholder="Last Name*"
          aria-label="Last Name*"
          required
        />
        <label for="last-name-input">Last Name</label>
      </div>
    </div>

    <div class="form-floating mb-3">
      <input
        id="email-input"
        type="email"
        name="Email"
        placeholder="Email*"
        aria-label="Email*"
        required
      />
      <label for="email-input">Email</label>
    </div>

    <div class="form-floating mb-3">
      <input
        id="job-title-input"
        type="text"
        name="Job Title"
        placeholder="Job Title*"
        aria-label="Job Title*"
        required
      />
      <label for="job-title-input">Job Title</label>
    </div>

    <div class="form-floating mb-5">
      <input
        id="company-name-input"
        type="text"
        name="Company Name"
        placeholder="Company Name*"
        aria-label="Company Name*"
        required
      />
      <label for="company-name-input">Company Name</label>
    </div>

    <div class="flex cursor-pointer items-center gap-3">
      <input
        id="marketing_consent"
        type="checkbox"
        name="Marketing Consent"
        value="✓"
        class="size-5 cursor-pointer appearance-none rounded-md border-0 bg-white text-blue ring-1 ring-black/10 ring-offset-1 before:opacity-0 before:transition-opacity checked:ring-blue checked:before:opacity-100 focus:ring-1 focus:ring-blue focus:ring-offset-1"
      />
      <label for="marketing_consent">I agree to receive communications about US SIF and upcoming events. We will never share
        your information.</label>
    </div>

    <input
      id="file-name-input"
      type="hidden"
      name="File Name"
      :value="downloadFileName"
    />

    <UiButton
      button-type="primary"
      button-theme="dark"
      button-icon="slash"
      class="mt-10"
      :class="{
        'cursor-progress opacity-60': pending,
      }"
    >
      <button type="submit">
        <div v-if="!pending">
          Download
        </div>
        <div v-if="pending">
          Loading...
        </div>
      </button>
    </UiButton>
  </form>
</template>
